<template>
  <div style="background-color: white;">
    <van-sticky :offset-top="50">
      <van-cell size="large">

        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <van-row justify="space-around">

            <van-col span="20">
              <span v-if="detail.questionType=='1'">{{detail.number}}.{{detail.questionName}}</span>
              <span v-if="detail.questionType!='1'">{{detail.questionName}}</span>
            </van-col>

            <van-col span="4"
                     style="text-align: right;">
              <van-icon name="share"
                        color="#1989fa"
                        @click="onClickShare" />

            </van-col>
          </van-row>
        </template>

        <template #label>
          <van-row justify="space-around">
            <van-col span="3">
              <van-tag v-if="detail.level==1"
                       color="#67c23a"
                       plain>简单</van-tag>
              <van-tag v-else-if="detail.level==2"
                       color="#e6a23c"
                       plain>中等</van-tag>
              <van-tag v-else
                       color="#f56c6c"
                       plain>困难</van-tag>

            </van-col>
            <van-col span="7">
              <van-icon name="clock"
                        color="#ff5722" /> {{detail.time}}
            </van-col>
            <van-col span="3">
              <van-icon name="fire"
                        color="#ee0a24" /> {{detail.frequency}}
            </van-col>
          </van-row>
        </template>
      </van-cell>
    </van-sticky>

    <van-button v-if="detail.questionType == 2"
                type="warning"
                size="small"
                :url="detail.url"
                block>本题 LeetCode 传送门</van-button>
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      试题解析
    </van-divider>
    <van-row v-if="detail.videoUrl != null"
             type="flex"
             justify="center">
      <van-col span="22">
        <iframe :src="detail.videoUrl"
                width="100%"
                height="200"
                scrolling="no"
                border="0"
                frameborder="no"
                framespacing="0"
                allowfullscreen="true"> </iframe>

      </van-col>
    </van-row>

    <van-row type="flex"
             justify="center">
      <van-col span="22">
        <div v-if="detail.questionDetail != null"
             class="markdown-body">
          <!-- <vue-markdown :source="detail.questionDetail"></vue-markdown> -->

          <viewer ref="markdownViewer"
                  :options="viewerOptions"
                  :height="'auto'"
                  :initialValue="detail.questionDetail"></viewer>

        </div>
        <div v-else>
          <van-empty description="解析整理中" />
        </div>
      </van-col>
    </van-row>

    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      参与讨论
    </van-divider>
    <van-row type="flex"
             justify="center">
      <van-col span="22">
        <Valine :options="valineOptions" />
      </van-col>
    </van-row>

    <van-share-sheet v-model="showShare"
                     title="立即分享"
                     :options="shareOptions"
                     @select="onSelect" />

  </div>
</template>

<script>
import { ImagePreview, Dialog, ShareSheet, Sticky, Button, Divider, Empty, Cell, Tag, Col, Row, Rate, Icon } from 'vant';
import Valine from './Valine.vue';

import 'prismjs/themes/prism.css';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import Prism from 'prismjs';
import 'prismjs/components/prism-go.js';
// import './style/toastui-editor.css';
import "@toast-ui/editor/dist/i18n/zh-cn";
import './toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/vue-editor';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';
// const axios = require("axios");


export default {
  props: {
    detail: {
      type: Object,
      required: true
    }
  },
  watch: {
    detail: {
      handler: function (newVal, oldVal) {
        console.log('detail changed from', oldVal, 'to', newVal);
        this.valineOptions.path = '/#/detail?item=0&questionType=' + this.detail.questionType + '&id=' + this.detail.id
        this.$nextTick(() => {
          this.$refs.markdownViewer.invoke('setMarkdown', this.detail.questionDetail);
        })
      },
      deep: true
    }
  },
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
    [ShareSheet.name]: ShareSheet,
    [Dialog.Component.name]: Dialog.Component,
    viewer: Viewer,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Sticky.name]: Sticky,
    Valine,
    [Empty.name]: Empty,
    [Rate.name]: Rate,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Row.name]: Row,

  },

  data () {
    return {
      showShare: false,
      shareOptions: [
        { name: '微信', icon: 'wechat' },
        // { name: '微博', icon: 'weibo' },
        // { name: '复制链接', icon: 'link' },
        { name: '分享海报', icon: 'https://img01.yzcdn.cn/vant/custom-icon-light.png' },
        // { name: '二维码', icon: 'qrcode' },
      ],
      viewerOptions: {
        plugins: [[codeSyntaxHighlight, { highlighter: Prism }]]
      },
      questionType: '',
      valineOptions: {
        appId: 'kWKlQCNOVzR8JT7EAPJXvanE-gzGzoHsz',
        appKey: 'nscorEe7TaRyn1DBFNicKsWH',
        placeholder: '欢迎大家留言交流，填写邮箱可接收回复信息哦！😎 支持markdown格式，点击右下角预览查看效果。',
        path: window.location.pathname,
      },
      rate: 1,
      done: 1
    };
  },
  filters: {
    questionTypeFilter (val) {
      switch (val) {
        case '1':
          return "GOLANG";
        case '2':
          return "数据结构与算法";
        case '3':
          return "数据库";
        case '4':
          return "操作系统";
        case '5':
          return "网络";
        case '6':
          return "系统设计";
        case '7':
          return "工具";
        case '8':
          return "通用能力";
        default:
          return "11GOLANG";
      }
    },
    rateFilter (val) {
      switch (val) {
        case 1:
          return "了解";
        case 2:
          return "熟悉";
        case 3:
          return "精通";
        default:
          return "未标记";
      }
    },
  },
  //初始化评论
  mounted () {
    this.valineOptions.path = '/#/detail?item=0&questionType=' + this.detail.questionType + '&id=' + this.detail.id
  },
  methods: {
    //分享 
    onSelect (shareOption) {

      if (shareOption.name == '分享海报') {
        var shareImage = 'https://image-1302243118.cos.ap-beijing.myqcloud.com/share/question_' + this.detail.id + '.jpg'

        const img = new Image();
        img.src = shareImage;
        img.onload = function () {
          console.log("Image loaded successfully.");
          ImagePreview({
            images: [
              shareImage,
            ],
            closeable: true,
          });
          this.showShare = false;
          // Do something if the image is loaded successfully
        };
        img.onerror = function () {
          console.log("Failed to load image.");
          // Do something if the image fails to load
          Dialog({ message: '暂无海报,请选择微信分享', theme: 'round-button', confirmButtonColor: '#0af' });
        };

      } else {
        Dialog({ message: '本题链接已复制到剪切板，前往微信分享给朋友吧！', theme: 'round-button', confirmButtonColor: '#0af' });
        this.showShare = false;
        let container = this.$refs.container
        var shareUrl = "分享个面试题给你 : https://m.golangroadmap.com/#/sharequestion?questionType=" + this.detail.questionType + '&id=' + this.detail.id + "。GOLANG ROADMAP一个专注Go语言学习、求职的社区。"
        this.$copyText(shareUrl, container)
      }
    },
    //打开分享
    onClickShare () {
      this.showShare = true
    },
  }
};
</script>

<style lang="less">
/* iphone plus 414px */
@media screen and (max-width: 414px) and (min-width: 361px) {
  .van-cell__label {
    max-width: 384px;
  }
}
/* iphone 320px */
@media screen and (max-width: 320px) {
  .van-cell__label {
    max-width: 290px;
  }
}

/* android 360px*/
@media screen and (max-width: 360px) and (min-width: 321px) {
  .van-cell__label {
    max-width: 330px;
  }
}
/* android 360px*/
@media screen and (max-width: 375px) and (min-width: 361px) {
  .van-cell__label {
    max-width: 345px;
  }
}

@media screen and (max-width: 480px) and (min-width: 415px) {
  .van-cell__label {
    max-width: 450px;
  }
}

@media screen and (max-width: 540px) and (min-width: 481px) {
  .van-cell__label {
    max-width: 510px;
  }
}
</style>
