<template>
  <div class="valine-wrapper">
    <div id="valine"></div>
  </div>
</template>

<script>

export default {
  name: 'Valine',
  props: {
    options: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  mounted: function () {
    this.initValine()
  },
  methods: {
    initValine () {
      const Valine = require('valine')
      const valineOptions = {
        el: '#valine',
        notify: false,
        verify: false,
        avatar: 'wavatar',
        visitor: true,
        recordIP: false,
        requiredFields: ['nick', 'mail'],
        ...this.options
      }

      new Valine(valineOptions)
    }
  },
  watch: {

    options: {
      handler: 'initValine',
      deep: true
    },
    '$route' (to, from) {
      if (to.path !== from.path) {
        // 切换页面时刷新评论
        // this.$router.go(0)
        setTimeout(() => {
          this.initValine()
        }, 300)
      }
    }
  }
}
</script>

<style lang="less">
</style>
